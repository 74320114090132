import {type ReactElement, useEffect, useState, useMemo} from 'react';
import {Home, Layers, Users, MessageCircle, Folder, Coffee, PhoneCall, Briefcase} from "react-feather";
import {Link} from "@uxu/design-system/components/atoms/link";
import {haveAccess} from "@uxu/utils/common/other";
import {useSiteConfig} from "@uxu/design-system/hooks/useSiteConfig";

export function RenderLinks(): ReactElement {
    const {config} = useSiteConfig();
    const [roleName, setRoleName] = useState<string>("USER_DEFAULT");

    useEffect(() => {
        const newRoleName = config.client.auth.roleName;
        if (typeof newRoleName === "string" && newRoleName !== roleName) {
            setRoleName(newRoleName);
        }
    }, [roleName, config.client.auth.roleName]);

    const isAdmin = useMemo(() => haveAccess(roleName, ["USER_ADMIN"]), [roleName]);

    const adminLinks = useMemo(() => (
        <>
            <li>
                <Link href="/dashboard/blogs" title="blogi">
                    <Coffee/>
                </Link>
            </li>
            <li>
                <Link href="/dashboard/comments" title="komentarze">
                    <MessageCircle/>
                </Link>
            </li>
            <li>
                <Link href="/dashboard/users" title="użytkownicy">
                    <Users/>
                </Link>
            </li>
            <li>
                <Link href="/dashboard/files" title="pliki">
                    <Folder/>
                </Link>
            </li>
            <li>
                <Link href="/dashboard/companies" title="firmy">
                    <Briefcase/>
                </Link>
            </li>
            <li>
                <Link href="/dashboard/phones" title="telefony">
                    <PhoneCall/>
                </Link>
            </li>
            <li>
                <Link href="/dashboard/apps" title="aplikacje">
                    <Layers/>
                </Link>
            </li>
        </>
    ), []);

    return (
        <>
            <li>
                <Link href="/dashboard" title="start">
                    <Home/>
                </Link>
            </li>
            {isAdmin ? adminLinks : null}
        </>
    );
}
